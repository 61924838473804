import * as React from "react";
import { ModalContextProvider } from "./src/context/ModalContext";
import { ThemeProvider } from "@mui/material";
import { mainTheme } from "./src/config/MUI/mainTheme";
import { onError } from "@apollo/client/link/error";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { CartContextProvider } from "./src/context/CartContext";

const WrapRootElement = ({ children }) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  dayjs.locale("ru");
  return (
    <CartContextProvider>
      <ModalContextProvider>
        <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>
      </ModalContextProvider>
    </CartContextProvider>
  );
};

export default WrapRootElement;
