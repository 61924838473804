import React, { createContext, useEffect, useState } from "react";

export const CartContext = createContext({
  cartItems: [],
  getCartItem: (id, type, variationId) => {
    return {};
  },
  addCartItem: ({ id, selectedSize, quantity = 1 }) => {},
  updateCartItem: (updatedCartItem = {}) => {},
  deleteCartItem: (id, variationId) => {},
  clearCart: () => {},
});

export const CartContextProvider = ({ children }) => {
  const locStoreCartItems =
    (typeof window !== "undefined" &&
      JSON.parse(localStorage.getItem("cartItems"))) ||
    [];

  const [cartItems, setCartItems] = useState(locStoreCartItems);

  useEffect(() => {
    typeof window !== "undefined" &&
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const getCartItem = (id, type, variationId) => {
    return cartItems.find(
      (someCartItem) =>
        someCartItem?.id === id &&
        (type !== "VARIABLE" ||
          someCartItem?.selectedSize?.databaseId === variationId)
    );
  };

  const addCartItem = ({ id, selectedSize, quantity = 1 }) => {
    setCartItems((prevCartItems) => [
      ...prevCartItems,
      { id, selectedSize, quantity },
    ]);
  };

  const updateCartItem = (updatedCartItem = {}) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((someCartItem) => {
        if (
          someCartItem.id === updatedCartItem.id &&
          someCartItem?.selectedSize?.databaseId ===
            updatedCartItem?.selectedSize?.databaseId
        ) {
          return {
            ...someCartItem,
            ...updatedCartItem,
          };
        }
        return someCartItem;
      })
    );
  };

  const deleteCartItem = (id, variationId) => {
    setCartItems((prevCartItems) =>
      prevCartItems.filter((someCartItem) =>
        variationId
          ? someCartItem?.id !== id ||
            someCartItem?.selectedSize?.databaseId !== variationId
          : someCartItem?.id !== id
      )
    );
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        getCartItem,
        addCartItem,
        updateCartItem,
        deleteCartItem,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
