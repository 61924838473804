import React, { createContext, useState } from "react";

export const ModalContext = createContext({
  isModalActive: () => {
    return false;
  },
  setModalActive: () => {},
  setModalInactive: () => {},
});

export const ModalContextProvider = ({ children }) => {
  const [activeModals, setActiveModals] = useState([]);

  const isModalActive = (modalId) => {
    return activeModals.includes(modalId);
  };

  const setModalActive = (modalId) => {
    setActiveModals((curActiveModals) => [
      ...curActiveModals.filter((id) => id !== modalId),
      modalId,
    ]);
  };

  const setModalInactive = (modalId) => {
    setActiveModals((curActiveModals) =>
      curActiveModals.filter((id) => id !== modalId)
    );
  };

  return (
    <ModalContext.Provider
      value={{ activeModals, isModalActive, setModalActive, setModalInactive }}
    >
      {children}
    </ModalContext.Provider>
  );
};
