import "./src/styles/global.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./src/styles/dadata.css";
import * as React from "react";
import WrapRootElement from "./wrap-root-element";

export const wrapRootElement = ({ element }) => {
  return <WrapRootElement>{element}</WrapRootElement>;
};
