import { createTheme } from "@mui/material";

const colors = {
  white: "#FFFFFF",
  black: "#000000",
  blue: "#324F7B",
  whiteBlue: "#EEF8FF",
  orange: "#FBBA00",
  red: "#E52330",
  green: "#00D415",
  gray: "C2CAD7",
};

const palette = createTheme({
  palette: {
    primary: {
      main: colors.blue,
      contrastText: colors.white,
      black: colors.black,
      white: colors.white,
    },
    secondary: {
      main: colors.whiteBlue,
      orange: colors.orange,
      red: colors.red,
      green: colors.green,
      gray: colors.gray,
    },
  },
});

const breakpoints = createTheme({});

export const mainTheme = createTheme(palette, {
  typography: {
    h2: {
      color: palette.palette.primary.contrastText,
      fontFamily: "Inter",
      fontWeight: 900,
      fontSize: 30,
      lineHeight: "120%",
      [breakpoints.breakpoints.up("md")]: {
        fontSize: 48,
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: 56,
      },
    },

    h3: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: 900,
      fontSize: 28,
      lineHeight: "120%",
      [breakpoints.breakpoints.up("md")]: {
        fontSize: 48,
      },
    },
    h4: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: 900,
      fontSize: 20,
      lineHeight: "120%",
      [breakpoints.breakpoints.up("md")]: {
        fontSize: 24,
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: 32,
      },
      [breakpoints.breakpoints.up("xl")]: {
        fontSize: 42,
      },
    },
    h5: {
      color: palette.palette.primary.contrastText,
      fontFamily: "Inter",
      fontWeight: 900,
      fontSize: 16,
      lineHeight: "120%",
      [breakpoints.breakpoints.up("sm")]: {
        fontSize: 18,
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: 24,
      },
    },
    h6: {
      color: palette.palette.primary.main,
      fontFamily: "Inter",
      fontWeight: 900,
      fontSize: 14,
      lineHeight: "120%",
      [breakpoints.breakpoints.up("sm")]: {
        fontSize: 16,
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: 18,
      },
    },

    body1: {
      textTransform: "none",
      color: palette.palette.primary.contrastText,
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "120%",
      [breakpoints.breakpoints.up("md")]: {
        fontSize: 16,
      },
      [breakpoints.breakpoints.up("xl")]: {
        fontSize: 16,
      },
    },
    subtitle1: {
      color: palette.palette.primary.contrastText,
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "120%",
      [breakpoints.breakpoints.up("md")]: {
        fontWeight: 400,
        fontSize: 18,
      },
    },
    caption: {
      textTransform: "none",
      color: palette.palette.primary.contrastText,
      display: "inline-block",
      fontFamily: "Inter",
      fontWeight: 300,
      fontSize: 10,
      lineHeight: "120%",
      [breakpoints.breakpoints.up("md")]: {
        fontSize: 14,
      },
      [breakpoints.breakpoints.up("lg")]: {
        fontSize: 14,
      },
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          color: palette.palette.primary.main,
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          color: palette.palette.primary.main,
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          color: palette.palette.primary.main,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "7px 15px",
        },
      },
      variants: [
        {
          props: {
            variant: "contained",
          },
          style: {
            borderRadius: "30px",

            textTransform: "none",
            boxShadow: "none",
            minWidth: 0,
          },
        },
        {
          props: {
            variant: "outlined",
          },
          style: {
            borderColor: palette.palette.primary.contrastText,
            borderWidth: 1.5,
            borderRadius: "30px",

            textTransform: "none",
            boxShadow: "none",
            minWidth: 0,
            transition: "background-color 0.2s ease-in-out",
            "&:hover": {
              borderColor: palette.palette.primary.contrastText,
              backgroundColor: "rgb(35 55 86 / 47%)",
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "25px",
        },
        input: {
          padding: "10px 18px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          color: palette.palette.primary.main,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: "30px",
          overflow: "hidden",
        },
        input: {
          backgroundColor: palette.palette.secondary.main,
          color: palette.palette.primary.main,
        },
        underline: {
          "&:after": {
            display: "none",
          },
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          cursor: "pointer",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: palette.palette.primary.main,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0 20px",
          [breakpoints.breakpoints.up("md")]: {
            padding: "0 40px",
          },
          [breakpoints.breakpoints.up("xl")]: {
            padding: "0 40px",
            maxWidth: `${breakpoints.breakpoints.values.xl}px !important`,
          },
        },
      },
    },
  },
});
